import React from 'react';
import './MenuSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench, faFill, faCogs, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

function MenuSection() {
    return (
        <section className="MenuSection">
            <Link to="/servis-techniky">
                <div className="menu-button">
                    <FontAwesomeIcon size="3x" icon={faWrench}></FontAwesomeIcon>
                    <div className="menu-name">Servis techniky</div>
                </div>
            </Link>
            <Link to="/dekontaminacia-peroxidom">
                <div className="menu-button">
                    <FontAwesomeIcon size="3x" icon={faFill}></FontAwesomeIcon>
                    <div className="menu-name">Dekontaminácia peroxidom</div>
                </div>
            </Link>
            <Link to="/ponuka-pristrojov">
                <div className="menu-button">
                    <FontAwesomeIcon size="3x" icon={faCogs}></FontAwesomeIcon>
                    <div className="menu-name">Ponuka prístrojov</div>
                </div>
            </Link>
            <Link to="/kontakt">
                <div className="menu-button">
                    <FontAwesomeIcon size="3x" icon={faEnvelopeOpenText}></FontAwesomeIcon>
                    <div className="menu-name">Kontakt</div>
                </div>
            </Link>
        </section>
    )
}

export default MenuSection;