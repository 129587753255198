import React from 'react';
import LogoCarousel from './LogoCarousel';
import './PartnersSection.css';

function PartnersSection() {
    return (
        <section className="PartnersSection">
            <LogoCarousel logos={[
                'agilent.png',
                'alfalaval.png',
                'applikon.png',
                'apv.png',
                'beckman.png',
                'broadley.png',
                'brunswick.png',
                'buchi.png',
                'dionex.png',
                'edwards.png',
                'eppendorf.png',
                'esco.png',
                'thermo.png',
                'vwr.png',
                'waters.png'
            ]} />
            <LogoCarousel reversed={true} logos={[
                'fisherbrand.png',
                'ge.png',
                'gea.png',
                'gerhardt.png',
                'heidolph.png',
                'hettich.png',
                'hitachi.png',
                'horiba.png',
                'ika.png',
                'illumina.png',
                'pall.png',
                'sartorious.png',
                'sorvall.png',
                'ulvac.png',
                'west.png',
                'yamato.png'
            ]} />
            <LogoCarousel logos={[
                'jeol.png',
                'julabo.png',
                'kern.png',
                'labconco.png',
                'melag.png',
                'mettlertoledo.png',
                'millipore.png',
                'oakton.png',
                'olympus.png',
                'pfeiffer.png',
                'sciex.png',
                'shimadzu.png',
                'sigma.png',
                'varian.png',
                'zirbus.png'
            ]} />
        </section>
    )
}

export default PartnersSection;