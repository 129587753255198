import "./Kontakt.css";
import { React, useState, useEffect } from 'react';
import { Field, Column, Control, Input, Icon, Textarea, Button, Message } from 'rbx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt, faEnvelopeOpenText, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

export default function Kontakt() {
    // React state hook
    const [nameForm, setNameForm] = useState("/");
    const [emailForm, setEmailForm] = useState("/");
    const [phoneForm, setPhoneForm] = useState("/");
    const [messageForm, setMessageForm] = useState("/");
    const [canSendForm, setCanSendForm] = useState(false);
    const [formResponse, setFormResponse] = useState('');

    // Effect hook gets called every time the component updates
    // Similar to 'componentDidUpdate'
    useEffect(() => {
        updateCanSendForm()
    });

    // Path to the PHP file that handles the contact form
    const CONTACT_API_PATH = "/bat/MailHandler.php";

    // A method that sends the contact form to the backend
    const sendMessage = () => {
        const bodyValue = JSON.stringify(
            {
                name: nameForm,
                email: emailForm,
                phone: phoneForm,
                message: messageForm,
                fax: 'nope',
                state: 'nope',
                stripHTML: 'true'
            }
        );
        console.log(bodyValue);
        const response = fetch(CONTACT_API_PATH, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: bodyValue
        })
            .then(response => response.text())
            .then((data) => {
                setFormResponse(data);
                setNameForm('/');
                setEmailForm('/');
                setPhoneForm('/');
                setMessageForm('/');
                setCanSendForm(false);
            })
    }

    // Event handlers for form fields
    const onFieldChangeName = (event) => {
        setNameForm(event.target.value);
    }

    const onFieldChangeEmail = (event) => {
        setEmailForm(event.target.value);
    }

    const onFieldChangePhone = (event) => {
        setPhoneForm(event.target.value);
    }

    const onFieldChangeMessage = (event) => {
        setMessageForm(event.target.value);
    }

    const updateCanSendForm = () => {
        if (
            nameForm !== '' && nameForm !== '/' &&
            phoneForm !== '' && phoneForm !== '/' &&
            emailForm !== '' && emailForm !== '/' &&
            messageForm !== '' && messageForm !== '/'
        ) {
            setCanSendForm(true);
        } else {
            setCanSendForm(false);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        sendMessage();
    }

    return (
        <div className="KontaktWrapper">
            <div className="Kontakt">
                <div className="form">
                    <p className="center">Kontaktujte nas prostedníctvom formulára, radi sa Vám ozveme späť</p>
                    <form onSubmit={handleSubmit}>
                        <Field horizontal>
                            <Field.Label>Kontaktné údaje:</Field.Label>
                            <Field.Body>
                                <Field>
                                    <Control iconLeft>
                                        <Input
                                            onChange={onFieldChangeName}
                                            type="text"
                                            placeholder="Meno a priezvisko"
                                            color={nameForm === "" ? "danger" : ""}
                                        />
                                        <Icon size="small" align="left">
                                            <FontAwesomeIcon icon={faUserAlt} />
                                        </Icon>
                                    </Control>
                                </Field>
                                <Field>
                                    <Control iconLeft>
                                        <Input
                                            onChange={onFieldChangeEmail}
                                            placeholder="Email"
                                            type="email"
                                            color={emailForm === "" ? "danger" : ""}
                                        />
                                        <Icon size="small" align="left">
                                            <FontAwesomeIcon icon={faEnvelopeOpenText} />
                                        </Icon>
                                    </Control>
                                </Field>
                            </Field.Body>
                        </Field>
                        <Field horizontal>
                            <Field.Label></Field.Label>
                            <Field.Body>
                                <Field>
                                    <Control iconLeft>
                                        <Input
                                            onChange={onFieldChangePhone}
                                            type="tel"
                                            placeholder="Telefónne číslo"
                                            color={phoneForm === "" ? "danger" : ""}
                                        />
                                        <Icon size="small" align="left">
                                            <FontAwesomeIcon icon={faPhone} />
                                        </Icon>
                                    </Control>
                                </Field>
                            </Field.Body>
                        </Field>
                        <Field horizontal>
                            <Field.Label>Váš dopyt:</Field.Label>
                            <Field.Body>
                                <Field>
                                    <Control iconLeft>
                                        <Textarea
                                            onChange={onFieldChangeMessage}
                                            color={messageForm === "" ? "danger" : ""}
                                            placeholder="Tu zadajte obsah správy, ktorú nám chcete poslať."
                                        >
                                        </Textarea>
                                    </Control>
                                </Field>
                            </Field.Body>
                        </Field>
                        <Field horizontal>
                            <Field.Label></Field.Label>
                            <Field.Body>
                                <Field>
                                    <Control>
                                        <Button disabled={!canSendForm} tooltip={!canSendForm ? "Všetky polia musia byť vyplnené" : ""}>Odoslať</Button>
                                    </Control>
                                </Field>
                            </Field.Body>
                        </Field>
                    </form>
                    {formResponse ?
                        <Message color={formResponse === "formResponse" ? "success" : "danger"}>
                            <Message.Body>
                                {formResponse}
                            </Message.Body>
                        </Message> : ''}
                </div>
            </div>
            <hr />
            <div className="KontaktAddr">
                <Column.Group>
                    <Column>
                        <FontAwesomeIcon icon={faEnvelope} /> <br />
                        labservis.eu <br />
                        Purly s.r.o. <br />
                        Holíčska 8 <br />
                        Bratislava <br />
                        <a href="mailto:servis@labservis.eu">servis@labservis.eu</a>
                    </Column>
                    <Column>
                        <FontAwesomeIcon icon={faPhone} /> <br />
                        SK: +421 915 117 775 <br />
                        CZ: +420 730 921 019 <br />
                    </Column>
                </Column.Group>

            </div>
        </div>
    );
}