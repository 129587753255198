import './TableOfContents.css';

export default function TableOfContents(props) {
    return (
        <div className="TableOfContents">
            <ul>
                {props.headings.map(heading => <a key={'list-item-link-' + heading.title} href={'#' + heading.id}><li key={'list-item-' + heading.title}>{heading.title}</li></a>)}
            </ul>
        </div>
    )
}