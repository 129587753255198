import { Navbar } from 'rbx';
import { Link } from 'react-router-dom';
import './HeaderNavigation.css';

export default function HeaderNavigation() {
    
    const checkHighlightForSubpage = (pageName) => {
        if(window.location.href.indexOf(pageName) > -1) {
            return "highlighted"
        } else {
            return ""
        }
    }

    return(
        <Navbar fixed="top" color="black">
            <Navbar.Brand>
                <Link to="/">
                    <Navbar.Item as="div">
                        <img
                            src="/logo-site.png"
                            alt=""
                            role="presentation"
                            width="33"
                            height="30"
                        />
                    </Navbar.Item>
                </Link>
                <Navbar.Burger />
            </Navbar.Brand>
            
            <Navbar.Menu>
                <Link to="/servis-techniky"><Navbar.Item as="div" className={checkHighlightForSubpage("/servis-techniky")}>Servis techniky</Navbar.Item></Link>
                <Link to="/dekontaminacia-peroxidom"><Navbar.Item as="div" className={checkHighlightForSubpage("/dekontaminacia-peroxidom")}>Dekontaminácia peroxidom</Navbar.Item></Link>
                <Link to="/ponuka-pristrojov"><Navbar.Item as="div" className={checkHighlightForSubpage("/ponuka-pristrojov")}>Ponuka prístrojov</Navbar.Item></Link>
                <Link to="/kontakt"><Navbar.Item as="div" className={checkHighlightForSubpage("/kontakt")}>Kontakt</Navbar.Item></Link>
            </Navbar.Menu>
        </Navbar>
    );
}