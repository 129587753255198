import './ButtonSection.css';
import { Link } from 'react-router-dom';

export default function ButtonSection(props) {
    return(
        <div className="ButtonSection">
            <Link to="/kontakt" className="order-button">
                Objednať servis
            </Link>
        </div>
    )
}