import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  cz: {
    translation: {
        "dekontaminacia" : {
            "title": `Dekontaminace peroxidem`,
            "paragraf1": `Dekontaminace peroxidem patří mezi bio-dekontaminační metody využívající peroxid vodíku
            H 2 O 2 ve formě plynu. Plynní peroxid zvaný taktéž VHP (Vapour Hydrogen Peroxide) je
            najúčinnejší možností dezinfekce, dekontaminace a sterilizacie prostorů. Peroxid vodíku
            působí na patogénní baktérie, huby, kvasinky, vírusy, bakteriofágy a mykoplazmy.
            Mechanizmus účinku spočívá v uvolňování aktívniho kyslíku, který dále reaguje s proteíny
            a enzými přítomnymi v membráně mikroorganizmů tak, že je oxiduje. Bunky a víry jsou
            nakonec působením kyslíku zničeny. Táto metoda zabezpečí znížení kontaminace až o 6
            řádů. Metóda je účinná i proti SARS-CoV-2 (COVID-19) a jným koronavirum.`,
            "paragraf2": `K dekontaminaci se používá běžný koncentrovaný peroxid vodíku, který se v našem zařízení
            Steris VHP1000 zplyní. Výhodou je, že pára má jenom běžnou okolní teplotu do 30°C. Toto
            je obrovským rozdílem od běžné sterilizacie, kdy je teplota páry až 134°C. Metoda
            dekontaminace peroxidem vodíku je vysoce ekologická, jelikož se nakonec peroxid
            v prostředí „rozpadne“ na vodu. Tahle metoda dekontaminace nevyužívá žádné jiné
            chemikálie které by prezistentně poškozovali prostředí, případně by mohli být nebezpečné.`,
            "paragraf3": `Dekontaminace probíhá v uzatvřeném prostoru, přístup osob musí být v čase procesu úplně
            omezen. Peroxidem dekontaminujeme a sterilizujeme čisté prostory, izolátory, laboratoře,
            sály do velikosti 280 m 3 , které sa dají uzavřít. Úroveň dekontaminace sledujeme pomocí
            biologických indikátorů. Tyto indikátory jsou rozmístněny do dekontaminovaného prostředí,
            s tím že obsahují milióny spor mikroorganizmů. Biochemickou reakcí po procesu umíme
            posoudit, jestli byla dekontaminace úspěšná.`
        },
        "laboratornaTechnika": {
            "title": `Laboratorní technika`,
            "paragraf1": `Provádíme servis laboratorní techniky na objednávku. Objednávku je možné jednoduše
            zaslat přímo prostřednictvím objednávkového formuláře nebo domluvit na telefonním čísle.
            Servis uskutečňujeme formou placeného výjezdu servisního technika. Protože jsou
            laboratorní přístroje komplikovaná zařízení, provádíme na místě jen základní servis, případně
            instalaci a zaškolení personálu.
            V servisním středisku disponujeme kvalitním diagnostickým zařízením a pokročilými
            diagnostickými metodami. Na skladě vedeme potřebné množství náhradních dílů pro
            laboratorní přístroje a laboratorní příslušenství.`,
            "gcTitle": `GC (plynová chromatografie)`,
            "paragraf2": `GC (plynová chromatografie)`
        }
        
    }
  },
  fr: {
    translation: {
      "Welcome to React": "Bienvenue à React et react-i18next"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;