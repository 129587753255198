import { Column, Button } from "rbx";
import "./PonukaPristrojov.css";
import Lightbox from 'react-image-lightbox';
import { useState } from 'react';
import 'react-image-lightbox/style.css';

export default function PonukaPristrojov() {

    const images = [
        '/img/products/milliq_1.png',
        '/img/products/milliq_2.png',
        '/img/products/milliq_3.png',
        '/img/products/milliq_4.png',
        '/img/products/milliq_5.png',
        '/img/products/milliq_6.png'
    ];  

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="PonukaPristrojov">
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
                <div className="ponuka">
                    <img alt="MILLI-Q Adavantage A10" width="250px" height="208px" src="/img/products/milliq_1.png"></img>
                    <Column.Group className="columnGroup">
                        <Column>
                        <h1>MILLI-Q Adavantage A10</h1>
                            <Column.Group className="details">
                                <Column>
                                    Stav: repasovaný
                                </Column>
                                <Column>
                                    Cena: 3410 EUR
                                </Column>
                            </Column.Group>
                        <p className="description">
                            Milli-Q jednotka slúži k príprave laboratórne čistej vody. Zariadene je po kompletnom výmene filtrov, kalibrovaní a otestovaní. Veľmi dobrý stav.
                        </p>
                        </Column>
                    </Column.Group>
                    <div className="morePhotosLine">
                        <Button onClick={() => setIsOpen(true)}>Všetky fotky</Button>
                    </div>
                </div>
        </div>
       
    )
}