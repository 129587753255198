import './LogoCarousel.css';
import { useState, useEffect } from 'react';

function LogoCarousel(props) {
    let stateBuffer = {};

    for(let index = 0; index < props.logos.length; index++) {
        stateBuffer[index] = false;
    }

    const [currentlySent, setCurrentlySent] = useState(stateBuffer);
    const [indexOfSent, setIndexOfSent] = useState(0);
    const [maxSent] = useState(props.logos.length - 1);
    

    let logoElementsBuffer = [];
    for(let index = 0; index < props.logos.length; index++) {
        const image = props.logos[index];
        logoElementsBuffer.push(<img className={currentlySent[image] ? (props.reversed === true ? 'reverse-animated' : 'animated') : ''} width="300" height="150" key={image} src={'img/logo/' + image} alt=''></img>);
    }

    const [logoElements, setLogoElements] = useState(logoElementsBuffer);

    useEffect(() => {
        const interval = setInterval(() => {
            // Get current display bool array
            let sentThings = currentlySent;

            // Set the new logo index to +1
            setIndexOfSent(indexOfSent + 1);
            
            // Display the newely created index
            sentThings[indexOfSent] = true;

            // Remove the 
            if(indexOfSent - 4 >= 0) {
                sentThings[indexOfSent - 4] = false;
            } else {
                sentThings[maxSent + 1 + indexOfSent - 4] = false;
            }
            
            // Update state buffer
            setCurrentlySent(sentThings);

            // Logo element magic so they update
            let logoElementsBuffer = [];
            for(let index = 0; index <= maxSent; index++) {
                const image = props.logos[index];
                logoElementsBuffer.push(<img className={currentlySent[index] ? (props.reversed === true ? 'reverse-animated' : 'animated') : ''} width="300" height="150" key={image} src={'img/logo/' + image} alt=''></img>);
            }

            // If current logo index is max, reset to 0
            if(indexOfSent === maxSent) {
                setIndexOfSent(0);
            }

            // Update logo elements
            setLogoElements(logoElementsBuffer);
        }, 3200);
        return () => clearInterval(interval);
    }, [currentlySent, setIndexOfSent, indexOfSent, setCurrentlySent, maxSent, logoElements, props.logos, props.reversed]);

    return (
    <div className="LogoCarousel">
        <div className="LogoViewport">
            {logoElements}
        </div>
    </div>
    );
}

export default LogoCarousel;