import './CompanyDescriptionSection.css';

export default function CompanyDescriptionSection(props) {
    return(
        <div className="CompanyDescriptionSection">
            <p>
                Labservis.eu ponúka kvalifikované služby v oblasti servisu, inštalácie a údržby špecalizovanej laboratórnej techniky od roku 2015, kedy začal ako český záručný a pozáručný servis priemyselnej a laboratórnej elektroniky. Momentálne pôsobí nielen v Českej republike, ale aj na Slovensku a v Rakúsku. Know-how získali od renomovanej globálnej spoločnosti dodávajúcej na trh analytické prístroje. Ponúkame najpriaznivejšie ceny na trhu v porovnaní s OEM výrobcami a veľmi flexibilné časy realizácie servisu. Riadime sa metódami správnej praxe a disponujeme zásobami rôznych náhradných dielov.
            </p>
        </div>
    )
}