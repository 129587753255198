import TitleSection from '../components/TitleSection';
import MenuSection from '../components/MenuSection';
import PartnersSection from '../components/PartnersSection';
import ButtonSection from '../components/ButtonSection';
import CompanyDescriptionSection from '../components/CompanyDescriptionSection';

export default function Home() {
    return (
        <div className="Home">
            <div className="preload-images">
                <img src="/pic1.jpg" alt=""></img>
                <img src="/pic2.jpg" alt=""></img>
                <img src="/pic3.jpg" alt=""></img>
            </div>
            <TitleSection />
            <CompanyDescriptionSection />
            <MenuSection />
            <PartnersSection />
            <ButtonSection />
        </div>
       
    )
}