import { Column, Container } from 'rbx';
import { Link } from 'react-router-dom';
import React from "react";
import TableOfContents from '../components/TableOfContents';
import "./Article.css";

export default function ServisTechnikyContent() {
    const headings = [
        {title: 'GC (plynová chromatografia)', id: 'gc-plynova-chromatografia'},
        {title: 'HPLC (kvapalinová chromatografia)', id: 'hplc-kvapalinova-chromatografia'},
        {title: 'Bioreaktory, reaktory a fermentory', id: 'bioreaktory-reaktory-a-fermentory'},
        {title: 'Odparky', id: 'odparky'},
        {title: 'Váhy', id: 'vahy'},
        {title: 'MF / UF a RO jednotky', id: 'mf-uf-a-ro-jednotky'},
        {title: 'Centrifugy', id: 'centrifugy'},
        {title: 'Homogenizátory', id: 'homogenizatory'},
        {title: 'pH metry', id: 'ph-metry'},
        {title: 'Laboratórne boxy a digestori', id: 'laboratorne-boxy-a-digestori'},
        {title: 'Peristaltické pumpy a čerpadlá', id: 'peristalticke-pumpy-a-cerpadla'},
        {title: 'Ultrazvukové vane', id: 'ultrazvukove-vane'},
        {title: 'Sušiarne a inkubátory', id: 'susiarne-a-inkubatory'},
        {title: 'Trepačky a kultivačné boxy', id: 'trepacky-a-kultivacne-boxy'},
        {title: 'Lyofilizátory', id: 'lyofilizatory'}
    ];

    const callback = function(entries) {
        console.log(entries);
        entries.forEach(entry => {
            const id = entry.target.getAttribute('id');
            if (entry.intersectionRatio > 0) {
              document.querySelector(`nav li a[href="#${id}"]`).parentElement.classList.add('active');
            } else {
              document.querySelector(`nav li a[href="#${id}"]`).parentElement.classList.remove('active');
            }
        });
    }

    let observers = [];

    headings.forEach(heading => {
        let options = {
            root: document.querySelector('#' + heading.id),
            rootMargin: '0px',
            threshold: 1.0
        }
        observers.push(new IntersectionObserver(callback, options));
    });

    // const scrollEvent = function(e) {
    //     console.log(e);
    // }

    // useEffect(() => {
    //     function watchScroll() {
    //         window.addEventListener("scroll", scrollEvent);
    //     }
    //     watchScroll();
    //     return () => {
    //         window.removeEventListener("scroll", scrollEvent);
    //     };
    // });

    return (
        <Column.Group className="Article">
            <Column className="table-of-contents-column" style={ {"textAlign" : "left", "backgroundColor" : "#232323" } } size="one-fifth">
                <TableOfContents headings={headings} />
            </Column>
            <Column>
            <Container className="container" style={ { 'textAlign' : 'left' } }>
                <h1 id='servis-techniky' >Servis techniky</h1>
                <p>Servisujeme a opravujeme akúkoľvek laboratórnu techniku od rôznych výrobcov. Na základe
                objednávky, ktorú si zadáte <Link to="/kontakt">tu</Link> alebo do systému na hlavnej stránke, alebo telefonicky na
                0900 000 000. Servis je realizovaný u Vás v laboratóriu našim servisným technikom.
                Laboratórne prístroje su zväčša komplikované zariadenia, a tak je na mieste možné vykonať
                len základný servis a údržbu, prípadne zaškolenie. Náročnejšie opravy vykonávame v našom
                servisnom centre na Priemyselnej 8 v Bratislave. Laboratórne prístroje je potrebné
                pravidelne čistiť, kontrolovat a kalibrovať a zabezpečiť tak ich plynulý chod a
                bezporuchovosť. Labservis.eu môže slúžiť jako Váš zmluvný partner pri údržbe a servise
                vašich zariadení.</p>
                <p>Naše servisné stredisko disponuje kvalitným diagnostickým zariadením, dokumentáciou,
                diagnostickými metódami a postupmi k úspešnej oprave vašeho prístroja. Na sklade
                disponujeme potrebným množstvom náhradných dielov.</p>

                <div id='gc-plynova-chromatografia' className="anchor" />
                <h2>GC (plynová chromatografia)</h2>
                <p>Plynová chromatografie GC je zariadenie, ktoré
                obsahuje tieto diely: detektor, ventily, zdroj plynu,
                riadiacu jednotku s interface, autosampler, izotermický
                priestor pre kolónu, dátové rozhranie, PC s ovládacím
                Software a tlačiarňou. GC zariadenie sa bežne používa
                k stanoveniu ľahko splyniteľných vzoriek.</p>
                <p>Servisujeme všetky značky, ako Hewlett Packard,
                Shimadzu, Carlo Erba, Thermo Fisher Scientific,
                Finnigan, Agilent Technologies, Waters, Varian, Perklin
                Elmer, Dionex
                </p>
                <Column.Group>
                    <Column className="img-column">
                        <img alt="" src="/img/1_GC2014.jpg"></img>
                    </Column>
                    <Column className="img-column">
                        <img alt="" src="/img/2_6890_service.jpg"></img>
                    </Column>
                </Column.Group>
                
                <p>Medzi najbežnejšie opravy pokazených plynových chromatografov patria opravy: detektorov,
                ventilov, riadiacich a zobrazovacích jednotiek, komunikačných modulov, termostatov,
                napájacích zdrojov, ovládacích modulov, mechanických častí a výmeny tesnení. Taktiež
                opravujeme hmotnostné detektory GCMS.</p>

                <div id='hplc-kvapalinova-chromatografia' className="anchor" />
                <h2 >HPLC (kvapalinová chromatografia)</h2>
                <p>Kvapalinová chromatografia HPLC je zariadenie zložené z komponentov a modulov. Sem
                patrí detektor (VWD, MWD, RID, DAD), interface, autosampler s chladeným uchovaním
                vzoriek, degasser, izotermický priestor pre kolónu, dátové rozhranie, pumpy (izokratické,
                binárne, kvartérne). HPLC je prepojené s PC, ktoré slúži na vyhodnotenie nameraných
                údajov. V praxi se využíva k stanoveniu rôznych látok, ktoré sú rozpustné.</p>
                <p>Opravujeme všetky značky, ako napr. Waters, Shimadzu, Hitachi Merck, Agilent, Dionex,
                Linear, Polaris, Rainin, Gilson, Hewlett Packard, Knauer, Eksignet, Perklin Elmer, Edwars,
                GE Pharmacia, Akta, Kortec, a iné.</p>
                <p>Medzi najčastejšie opravované časti patria: pumpy a čerpadlá, ventily, detektory, riadiace
                jednotky, sieťové a napájacie zdroje, výhrevné moduly, tesnenie, pretesňovanie, čistenie a
                údržba. Zároveň opravujeme a udržujeme jednotky preparatívnej chromatografie FPLC,
                Flash chromatografie a separačné jednotky s podobným princípom funkčnosti.</p>
                
                <Column.Group>
                    <Column className="img-column">
                        <p>Doporučujeme časté menenie HPLC kolón, v závislosti najmä od stúpajúceho tlaku. Niekedy
                        se však kolony dají čistiť, prípadne opraviť podľa rozsahu poškodenia. U pilotných a
                        výrobných kolón sme schopní meniť piesty, frity a sitá, tesnenia, spojovacie a mechanické
                        diely, hadice, konektory, redukcie a hadičky. Opravujeme kolóny značiek: Phenomenex,
                        Millipore, ACE, Biotage, Amersham, Pharmacia, Kromasil, Thermo Scientific, Amicon,
                        Restele, VIB J&amp;W, Supelco, Grace, Varian, Bruker, a podobné.</p>
                    </Column>
                    <Column className="img-column">
                        <img alt="" src="/img/3_HPLC_Agilent.jpg"></img>
                    </Column>
                </Column.Group>

                <div id='bioreaktory-reaktory-a-fermentory' className="anchor" />
                <h2>Bioreaktory, reaktory a fermentory</h2>
                <p>Laboratórne a pilotné fermentory alebo biostaty sú
                komplexné zariadenia, ktoré udržujú podmienky
                procesov. Laboratórne bioreaktory sa využívajú
                k submerznej kultivácií buniek, pre chemické,
                enzymatické alebo biochemické procesy. Na rozdiel od
                sklenených reaktorov, ktoré sa využívajú v organickej
                chémií maju viacero sond a možnosť sterilizácie
                v autokláve alebo SIP / CIP.</p>
                <p>Udržujeme a servisujeme bioreaktory značiek: New Brunswick, Sartorius Braun, Millipore,
                LSL Biolafitte, DCI, BioNet, Infors HT, Cellexus, Bellco, VirTis, Applikon, Mettler Toledo, GE,
                Wave, Eppendorf, Broadly James</p>
                <p>Fermentory sú známe svojou komplikovanosťou a veľkou variabilitou konečného prevedenia.
                Medzi najbežnejšie zásahy patrí: čistenie, výmeny tesnení, hadičiek, konektorov a ventilov,
                opravy alebo výmeny motorov, miešadiel a ložísk. Dalej servisujeme: riadiace jednotky,
                senzory a regulátory, káblové rozvody a prepojenia, zobrazovacie a výstupné jednotky.</p>
                <Column.Group>
                    <Column className="img-column">
                        <img alt="" src="/img/4_30-liters-Bioreactor.jpg"></img>
                    </Column>
                    <Column className="img-column">
                        <img alt="" src="/img/5_Eppendorf_2.jpg"></img>
                    </Column>
                </Column.Group>

                <div id='odparky' className="anchor" />
                <h2 >Odparky</h2>
                <Column.Group>
                    <Column className="img-column">
                        <p>Odparky sú elektormechanické zariadenia udržujúce rôzne teploty a stupne vákua, prípadne
                        zniženého tlaku. Vykonávame opravy a repasáciu membránových aj rotačných vývev
                        a tlakových púmp, riadiacich, zobrazovacích a ovládacích jednotiek, motorov, náhonov a
                        rozvodov, výhrevných kúpeľov a výhrevných elementov. Servisujeme odparky značiek: Büchi, IKA, Heidolph, Organomation, Hiranuma, Labconco,
                        Scilogex, Janke &amp; Kunkel</p>
                    </Column>
                    <Column className="img-column">
                        <img alt="" src="/img/6_Bu-R205-2.jpg"></img>
                    </Column>
                </Column.Group>

                <div id='vahy' className="anchor" />
                <h2 >Váhy</h2>
                <p>Laboratórne váhy su neoddeliteľnou súčasťou každého laboratória. Vykonávame na nich
                jednoduché procesy váženia.</p>
                <p>Opravujeme váhy značiek: Kern, Fisherbrand, Mettler Toledo, Sartorius, Jennings a iné.
                Laboratórne váhy sú veľmi citlivé zariadenia. Akákoľvek manipulácia musí prebiehať v
                kontrolovaných podmienkach. Váhy se dajú servisovať len do istej miery. Opravujeme
                sieťové zdroje, riadiace jednotky. Ak je to možné, zobrazovacie jednotky vymieňame za
                nové. Servis hmotnostných senzorov je množný len v niektorých prípadoch.</p>

                <div id='mf-uf-a-ro-jednotky' className="anchor" />
                <h2 >MF / UF a RO jednotky</h2>
                <p>Jednotky sa využívajú v moderných biotech laboratóriach ako aj na úpravu vody.
                Mikrofiltračné, ultrafiltračné jednotky a jednotky reveznej osmózy obsahujú tlakové čerpadlá,
                rúrkové alebo hadicové rozvody, konzoly a držiaky membrány. Čerpadlá sú servisivateľné a
                repasovateľné, dalej sa dajú opravovať riadiace jedntky, senzory a transducerzy a
                manometre, zdroje. Pretesňujeme všetky jednotky.</p>
                <p>Servisujeme jednotky značiek: Sartorius, Pall, Millipore, GE healthcare, SciPro, Alfa-Laval</p>

                <div id='centrifugy' className="anchor" />
                <h2 >Centrifugy</h2>
                <p>Centrifugy rozdeľujeme na diskontinuální, laboratorní a kontinuální pilotné a výrobné.
                Centrifugy majú široké uplatnenie v separácií pevných a kvapalných častíc. Jedná sa
                o zariadenie s vysokou mechanickou presnosťou. Dokážeme meniť mechanické diely
                centrifugy, remene, ložiská a iné mechanické diely. Opravujeme taktiež elektrické časti,
                káblové rozvody, ventily, tesnenia, chladenia, a ovládacie jednotky.</p>
                <p>Servisujeme odstredivky značiek: Jouan, Sigma, Gotech, Rotanta, Heraeus, Sorvall Kuhl,
                Thermo Electron, DiaMed, Beckman, Eppendorf, Hettich, Baxter, Centurion, Clifton, ALC,
                IEC, MSE, ScanSpeed, Clay Adams, Thermo Scientific, Sanyo, Alfa-Laval, Westfalia, a iné.</p>

                <div id='homogenizatory' className="anchor" />
                <h2 >Homogenizátory</h2>
                <p>Homogenizátory, a mikrofluidizátory sa používajú na homogenizáciu kvapalného materiálu a
                sú to vysokotlaké zariadenia. Meníme: tesnenia, remene, homogenizačné ventily (hlavu), a
                po objednaní špecifických dielov aj mechanické časti tlakových zariadení (piesty, bloky,
                krúžky, a pod...) Opravujeme elektronické časti homeogenizátorov, čidlá, kábelové rozvody,
                meníme olej v prevodovke.</p>
                <p>Servisujeme homogenizátory značiek: GEA, APV Rannie, Silverson, Dispermat, IKA,
                Heidolph</p>

                <div id='ph-metry' className="anchor" />
                <h2 >pH metry</h2>
                <p>pH metre sú v dnešnej dobre multifunkčné laboraátorne zariadenia. Väčšinou sú
                konštruvané ako prenosné, stolové alebo panelové. Merajú nielen veličiny ako je pH, ale aj
                teplotu, vodivosť, redukčný potenciál . Poškodenie a chyby pH metrov sú v praxi velmi
                bežné, a pre našich zákazníkov na nich meníme: pH, vodivostné, teplotné a iné sondy
                (elektródy), baterie a akumulátory. Servisujeme: riadiace jednotky, zobrazovacie jednotky,
                káble a napájecie zdroje, konektory, ďalej opravujeme prístroje poškodené kvapalinou.</p>
                <p>Servisujeme pH metre značiek: WTW, Hanna Instruments, Mettler Toledo, Endress +
                Hauser, Courage + Khazaka, Knick, Sartorius, Schott, Jenway, HACH, Delta Ohm, Inolab,
                Testo, Beckaman, Milwaukee, Yokogawa, Oakton, Thermo Scientific, Omega, Denver
                Instruments, Ohaus, Horiba, a iné.</p>

                <div id='laboratorne-boxy-a-digestori' className="anchor" />
                <h2 >Laboratórne boxy a digestori</h2>
                <p>V každom laboratóriu sa nachádza digestor, ktorý se využíva na odtah par, skladovánie
                rozpoúšťadiel, prácu s nebezpečnými látkami. V biologickém, biochemickém alebo
                biotechnologickom laboratóriu se používajú boxy s laminárnym proúdením - takzvané
                laminárne boxy. Laboratórne boxy a laboratórne diestory sú jednoduché zariadenia, a tak je
                možné ich jednoducho udržovať, opravujeme osvetlenie, vzduchovou cirkuláciu, elektrické
                rozvody, prípojky, ventilátory. Meníme motory - ventilátory, a vykonávame celkové odborné
                čistenie zariadení.</p>

                <div id='peristalticke-pumpy-a-cerpadla' className="anchor" />
                <h2 >Peristaltické pumpy a čerpadlá</h2>
                <p>Peristalické pumpy se využívajú k dávkovániu kvapalín, ide o pomerne jednoduché
                zariadenia, které obsahujú motor, riadiacu elektroniku a samotné čerpadlo, kam se pripojujjú
                špeciálne hadice. V peristaltických pumpách meníme motory a mechaniku čerpadiel,
                servisujeme elektroniku a mechanické diely.</p>
                <p>Servis peristaltických čerpadiel značiek: Pericor, Masterflex, LKB, Jonan, HYS, Miele,
                Ismatec, Cavro, Tetra, RCT, Harton, Cole Palmer, Watson Marlow, Accuramatic, Ismatec, a
                iné.</p>

                <div id='ultrazvukove-vane' className="anchor" />
                <h2 >Ultrazvukové vane</h2>
                <p>Ultrazvukové vane využívajú fyzikálnych vlastností pôsobenia ultrazvuku o frekvenciách
                28kHz a 40kHz. Nejčastejšiou příčinou poruchy bývajú žiariče, ktoré je možno vymeniť za
                nové. Tiež sa stáva, že prístroj sa poškodí kvapalinou, čo je vo väčšine prípadoch
                opraviteľné.</p>
                <p>Servisujeme ultrazvukové vane značek: Jelenko, Fisher, Branson, Crest, Midmark,
                Patterson, Dexon, Langford, Spectro, Sonorex, KLN, Sonoplus</p>

                <div id='susiarne-a-inkubatory' className="anchor" />
                <h2 >Sušiarne a inkubátory</h2>
                <p>Laboratórne sušiarne sú jednoduché zařiadenia využívané k sušeniu materiálu. Opravujeme
                sušiarne, vákuové sušiarne a laboratorne inkubátory. Inkubátory se v praxi využívajú ku
                kultivácií mikroorganizmov.</p>
                <p>Servisujeme sušiarne značiek: Binder, Memmert, Sherwood, BMT, BUCHI, Venticell,
                Vacucell, Durocell, Ecocell, Fisher, Welch, Vötsch, Wisd, Tokki, Heraeus, Simac, Ehret,
                Labotect, Infors, Sanyo a iné.</p>

                <div id='trepacky-a-kultivacne-boxy' className="anchor" />
                <h2 >Trepačky a kultivačné boxy</h2>
                <p>Laboratoratórne trepačky a kultivačné boxy se využívajú k submerznej kultivácií
                mikroorganizmov, alebo biochemickým reakciám a extrakciám. Zariadenia sú konštruované s
                excentrickým motorom s kyvnou plošinou spolu s elektronickou regulacoiu otáčiek a teploty.
                Opravujeme a vymieňame motory, mechanické diely, riadiacu elektroniku a čidlá.</p>
                <p>Servisujeme trepačky a boxy značiek: Binder, Memmert, Labotect, Heraeus, NuAire, Mytron,
                Biometra, BMG, Melag, Boekel, Gerhardt, VWR, Robbins, Forma, New Brunswick, Fisher
                Scientific, Infors, IKA, BMT, WTW, BSK, Ivymen, a jiné.</p>

                <div id='lyofilizatory' className="anchor" />
                <h2 >Lyofilizátory</h2>
                <p>Lyofilizácia je proces vákuového vymrazovania (mrazenia), a sušenia. Zariadenia pracujú v
                tlakoch niekoľko desiatok až stoviek mili Barů, podla procesu. Lyofilizátor je konštruovaný
                ako zariadenie pozostávajúce z jednej hlavnej časti, prípadne aj z viacerých častí.
                Neoddeliteľnou súčasťou je výveva. Lyofilzátor bývá väčšinou vybavený výsuvnými policami
                alebo táckami. Lyofilizátory sú zložité elektromechanické zariadenia pracujúce vo vákuu,
                dokážeme ich úspešne servisovať. Každé zariadenie obsahuje vývevu, ktorú je nutné
                pravidelne kontrolovať a udržovať. Výveva obsahuje špeciálny olej s vysokou viskozitou,
                ktorý se musí pravidelne menit, zvyšuje sa tým účinnosť vývevy. Vykonáváme výmeny
                olejov, opravy chladiacich častí, elektroniky, rozvodov, termo čidiel, mechaniky políc.</p>
                <p>Servisujeme lyofilizátory značiek: Labconco, Virtis, Millrock, Martin Christ, Heto Holten, SP
                scientific, a iné.</p>
                </Container>
            </Column>
        </Column.Group>
    );
}