import './App.css';
import Home from './pages/Home';
import ServisTechnikyContent from './pages/ServisTechnikyContent';
import DekontaminaciaPeroxidom from './pages/DekontaminaciaPeroxidom';
import HeaderNavigation from './components/HeaderNavigation';
import PonukaPristrojov from './pages/PonukaPristrojov';
import Kontakt from './pages/Kontakt';
import ScrollToTop from './lib/ScrollToTop';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/servis-techniky">
            <HeaderNavigation />
            <ServisTechnikyContent />
          </Route>
          <Route path="/dekontaminacia-peroxidom">
            <HeaderNavigation />
            <DekontaminaciaPeroxidom />
          </Route>
          <Route path="/ponuka-pristrojov">
            <HeaderNavigation />
            <PonukaPristrojov />
          </Route>
          <Route path="/kontakt">
            <HeaderNavigation />
            <Kontakt />
          </Route>
          <Route path="/">
            <HeaderNavigation />
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
