import { Column, Container } from 'rbx';
import React from "react";
import TableOfContents from '../components/TableOfContents';
import "./Article.css";

export default function DekontaminaciaPeroxidom() {
    const headings = [
        
    ];

    const callback = function(entries) {
        console.log(entries);
        entries.forEach(entry => {
            const id = entry.target.getAttribute('id');
            if (entry.intersectionRatio > 0) {
              document.querySelector(`nav li a[href="#${id}"]`).parentElement.classList.add('active');
            } else {
              document.querySelector(`nav li a[href="#${id}"]`).parentElement.classList.remove('active');
            }
        });
    }

    let observers = [];

    headings.forEach(heading => {
        let options = {
            root: document.querySelector('#' + heading.id),
            rootMargin: '0px',
            threshold: 1.0
        }
        observers.push(new IntersectionObserver(callback, options));
    });

    // const scrollEvent = function(e) {
    //     console.log(e);
    // }

    // useEffect(() => {
    //     function watchScroll() {
    //         window.addEventListener("scroll", scrollEvent);
    //     }
    //     watchScroll();
    //     return () => {
    //         window.removeEventListener("scroll", scrollEvent);
    //     };
    // });

    return (
        <Column.Group className="Article">
            <Column className="table-of-contents-column" style={ {"textAlign" : "left", "backgroundColor" : "#232323" } } size="one-fifth">
                <TableOfContents headings={headings} />
            </Column>
            <Column>
                <Container className="container" style={ { 'textAlign' : 'left' } }>
                    <h1>Dekontaminácia peroxidom</h1>
                    <p></p>
                    <p>K dekontaminácií sa používa bežný koncentrovaný peroxid vodíka, ktorý sa v našom
                    zariadení Steris VHP1000 splyní. Výhodou pritom je, že para má len bežnú okolitú teplotu do
                    30°C. Toto je obrovským rozdielom od bežnej sterilizácie, kedy je teplota pary až 134°C.
                    Metóda dekontaminácie peroxidom vodíka je vysoko ekologická, kedže nakoniec sa peroxid
                    v prostredí „rozpadne“ na vodu. Naša metóda dekontaminácie nevyužíva žiadne iné
                    chemikálie ktoré by prezistentne zotrvávali v prostredí, prípadne by mohli byť nebezpečné.</p>
                    <p>Dekontaminácia prebieha v uzatvorenom priestore, prístup osôb musí byť počas procesu
                    zamedzený. Peroxidom dekontaminujeme a sterilizujeme peroxidom čisté priestory,
                    izolátory, laboratóriá, sály do veľkosti 280 m 3 , ktoré sa dajú uzavrieť. Úroveň dekontaminácie
                    sledujeme pomocou biologických indikátorov. Tieto indikátory sú rozmiestnené do
                    dekontaminovaného prostredia, s tým že obsahujú milióny spór mikroorganizmov.
                    Biochemickou reakciou dokážeme po procese posúdiť, či bola dekontaminácia úspešná.</p>
                    <Column.Group>
                    <Column className="img-column">
                        <img alt="" src="/img/2100976.jpg"></img>
                    </Column>
                    <Column className="img-column">
                        <img alt="" src="/img/VHP-1000ED_Control.jpg"></img>
                    </Column>
                    <Column className="img-column">
                        <img alt="" src="/img/20190709-_K8A4067_jpg.jpg"></img>
                    </Column>
                </Column.Group>
                </Container>
            </Column>
        </Column.Group>
    );
}