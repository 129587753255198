import React, { useState, useEffect } from 'react';
import './TitleSection.css';
import { Column, Title } from 'rbx';
import "rbx/index.css";

function TitleSection() {
    // How many ms should pass before the header changes
    // this only applies to the text, if you wanna change the fade animation check TileSection.css
    const pageInterval = 5000;

    const pageContent = [
        {image: 'pic1.jpg', title: '<b>Servis</b> laboratórnych prístrojov', description: 'Servisujeme a opravujeme akúkoľvek laboratórnu techniku od rôznych výrobcov.'},
        {image: 'pic2.jpg', title: '<b>Dekontaminácia</b> parami peroxidu', description: 'Bla bla'},
        {image: 'pic3.jpg', title: 'Ponuka <b>prístrojov</b>', description: 'Bla bla'}
    ]

    // The page of the rotating titles
    const [page, setPage] = useState(0);
    const [active, setActive] = useState(false);

    useEffect(() => {
        let interval = null;
        if (active) {
          interval = setInterval(() => {
            setPage(page !== 2 ? page + 1 : 0);
          }, pageInterval);
        }
        setActive(true);
        return () => clearInterval(interval);
      }, [active, page]);

    return (
        <section className="TitleSection">
            {/* <div className="utils">
                <div><FontAwesomeIcon icon={faPhone} /> +421 900 000 000</div>
                <div className="langs">
                    <div className="lang">CZ</div>
                    <div className="lang">SK</div>
                    <div className="lang">EN</div>
                </div>
            </div> */}
            <div style={ {'backgroundImage' : 'url(' + pageContent[page].image + ')' } } className="carousel-desktop">
                <div className="carousel-desktop-content">
                    <Column.Group>
                        <Column>
                            <img src="/logo-site.png" alt="" />
                        </Column>
                        <Column className="text-column">
                            <div className={'horizontal-center'}>
                                <div>
                                    <Title dangerouslySetInnerHTML={{__html: pageContent[page].title}} className="left-align"></Title>
                                </div>
                            </div>
                        </Column>
                    </Column.Group>
                </div>
            </div>
        </section>
    );
}

export default TitleSection;
